*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: ui-sans-serif, system-ui, AppleSystem, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

h1, h2 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b {
  font-weight: bolder;
}

code, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

button, input, textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button {
  text-transform: none;
}

button, [type="button"] {
  -webkit-appearance: button;
  background-color: rgba(0, 0, 0, 0);
  background-image: none;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

h1, h2, pre {
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::-ms-input-placeholder, textarea::-ms-input-placeholder {
  opacity: 1;
  color: #9ca3af;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button {
  cursor: pointer;
}

svg {
  vertical-align: middle;
  display: block;
}

[hidden] {
  display: none;
}

:root {
  background-color: hsla(var(--b1) / var(--tw-bg-opacity, 1) );
  color: hsla(var(--bc) / var(--tw-text-opacity, 1) );
}

html {
  -webkit-tap-highlight-color: transparent;
}

:root {
  --p: 326 100% 74%;
  --pf: 326 100% 59%;
  --sf: 265 89% 62%;
  --af: 31 100% 57%;
  --nf: 230 15% 24%;
  --b2: 231 15% 17%;
  --b3: 231 15% 15%;
  --pc: 326 100% 15%;
  --sc: 265 100% 16%;
  --ac: 31 100% 14%;
  --nc: 230 71% 86%;
  --inc: 191 100% 15%;
  --suc: 135 100% 13%;
  --wac: 65 100% 15%;
  --erc: 0 100% 93%;
  --rounded-box: 1rem;
  --rounded-btn: .5rem;
  --rounded-badge: 1.9rem;
  --animation-btn: .25s;
  --animation-input: .2s;
  --btn-text-case: uppercase;
  --btn-focus-scale: .95;
  --border-btn: 1px;
  --tab-border: 1px;
  --tab-radius: .5rem;
  --s: 265 89% 78%;
  --a: 31 100% 71%;
  --n: 230 15% 30%;
  --b1: 231 15% 18%;
  --bc: 60 30% 96%;
  --in: 191 97% 77%;
  --su: 135 94% 65%;
  --wa: 65 92% 76%;
  --er: 0 100% 67%;
}

* {
  scrollbar-color: initial;
  scrollbar-width: initial;
}

*, :before, :after {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, .5);
  --tw-ring-offset-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-ring-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow-colored: 0 0 rgba(0, 0, 0, 0);
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.badge {
  height: 1.25rem;
  width: fit-content;
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: hsl(var(--n) / var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: hsl(var(--n) / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--nc) / var(--tw-text-opacity) );
  border-radius: var(--rounded-badge, 1.9rem);
  justify-content: center;
  align-items: center;
  padding-left: .563rem;
  padding-right: .563rem;
  font-size: .875rem;
  line-height: 1.25rem;
  transition-property: color, background-color, border-color, -webkit-text-decoration-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: inline-flex;
}

.btn {
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-color: rgba(0, 0, 0, 0);
  border-color: hsl(var(--n) / var(--tw-border-opacity) );
  text-align: center;
  border-radius: var(--rounded-btn, .5rem);
  height: 3rem;
  min-height: 3rem;
  text-transform: uppercase;
  text-transform: var(--btn-text-case, uppercase);
  border-width: var(--border-btn, 1px);
  animation: button-pop var(--animation-btn, .25s) ease-out;
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--n) / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--nc) / var(--tw-text-opacity) );
  flex-wrap: wrap;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: .875rem;
  font-weight: 600;
  line-height: 1em;
  -webkit-text-decoration-line: none;
  text-decoration-line: none;
  transition-property: color, background-color, border-color, -webkit-text-decoration-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: inline-flex;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.card {
  border-radius: var(--rounded-box, 1rem);
  flex-direction: column;
  display: flex;
  position: relative;
  overflow: hidden;
}

.card:focus {
  outline-offset: 2px;
  outline: 2px solid rgba(0, 0, 0, 0);
}

.card-body {
  padding: var(--padding-card, 2rem);
  flex-direction: column;
  flex: auto;
  gap: .5rem;
  display: flex;
}

.card-body :where(p) {
  flex-grow: 1;
}

.divider {
  height: 1rem;
  white-space: nowrap;
  flex-direction: row;
  align-self: stretch;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
}

.divider:before, .divider:after {
  content: "";
  height: .125rem;
  width: 100%;
  flex-grow: 1;
}

.label {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  justify-content: space-between;
  align-items: center;
  padding: .5rem .25rem;
  display: flex;
}

.input {
  height: 3rem;
  border-width: 1px;
  border-color: hsl(var(--bc) / var(--tw-border-opacity) );
  --tw-border-opacity: 0;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b1) / var(--tw-bg-opacity) );
  border-radius: var(--rounded-btn, .5rem);
  flex-shrink: 1;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: .875rem;
  line-height: 2;
  transition-property: color, background-color, border-color, -webkit-text-decoration-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.input-group {
  width: 100%;
  align-items: stretch;
  display: flex;
}

.input-group > *, .input-group > .input {
  border-radius: 0;
}

.input-group :where(span) {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b3, var(--b2)) / var(--tw-bg-opacity) );
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
}

.input-group :first-child {
  border-top-left-radius: var(--rounded-btn, .5rem);
  border-top-right-radius: 0;
  border-bottom-left-radius: var(--rounded-btn, .5rem);
  border-bottom-right-radius: 0;
}

.input-group :last-child {
  border-top-left-radius: 0;
  border-top-right-radius: var(--rounded-btn, .5rem);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: var(--rounded-btn, .5rem);
}

.input-group-vertical {
  flex-direction: column;
}

.input-group-vertical :first-child {
  border-top-left-radius: var(--rounded-btn, .5rem);
  border-top-right-radius: var(--rounded-btn, .5rem);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-vertical :last-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: var(--rounded-btn, .5rem);
  border-bottom-right-radius: var(--rounded-btn, .5rem);
}

.link {
  cursor: pointer;
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

.swap {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  align-content: center;
  justify-content: center;
  display: inline-grid;
  position: relative;
}

.swap > * {
  grid-row-start: 1;
  grid-column-start: 1;
  transition-property: transform, opacity;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.swap input {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
}

.tabs {
  flex-wrap: wrap;
  align-items: flex-end;
  display: flex;
}

.tab {
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-align: center;
  height: 2rem;
  --tab-padding: 1rem;
  --tw-text-opacity: .5;
  --tab-color: hsla(var(--bc) / var(--tw-text-opacity, 1) );
  --tab-bg: hsla(var(--b1) / var(--tw-bg-opacity, 1) );
  --tab-border-color: hsla(var(--b3) / var(--tw-bg-opacity, 1) );
  color: var(--tab-color);
  padding-left: var(--tab-padding, 1rem);
  padding-right: var(--tab-padding, 1rem);
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: .875rem;
  line-height: 2;
  display: inline-flex;
  position: relative;
}

.textarea {
  min-height: 3rem;
  border-width: 1px;
  border-color: hsl(var(--bc) / var(--tw-border-opacity) );
  --tw-border-opacity: 0;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b1) / var(--tw-bg-opacity) );
  border-radius: var(--rounded-btn, .5rem);
  flex-shrink: 1;
  padding: .5rem 1rem;
  font-size: .875rem;
  line-height: 2;
  transition-property: color, background-color, border-color, -webkit-text-decoration-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.btn-outline .badge {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nf, var(--n)) / var(--tw-border-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--nc) / var(--tw-text-opacity) );
}

.btn-outline.btn-primary .badge {
  --tw-border-opacity: 1;
  border-color: hsl(var(--p) / var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: hsl(var(--p) / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--pc) / var(--tw-text-opacity) );
}

.btn-outline.btn-secondary .badge {
  --tw-border-opacity: 1;
  border-color: hsl(var(--s) / var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: hsl(var(--s) / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--sc) / var(--tw-text-opacity) );
}

.btn-outline:hover .badge {
  --tw-border-opacity: 1;
  border-color: hsl(var(--b2, var(--b1)) / var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2, var(--b1)) / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--bc) / var(--tw-text-opacity) );
}

.btn-outline.btn-primary:hover .badge {
  --tw-border-opacity: 1;
  border-color: hsl(var(--pc) / var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: hsl(var(--pc) / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--p) / var(--tw-text-opacity) );
}

.btn-outline.btn-secondary:hover .badge {
  --tw-border-opacity: 1;
  border-color: hsl(var(--sc) / var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: hsl(var(--sc) / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--s) / var(--tw-text-opacity) );
}

.btn:active:hover, .btn:active:focus {
  transform: scale(var(--btn-focus-scale, .95) );
  animation: none;
}

.btn:hover {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nf, var(--n)) / var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nf, var(--n)) / var(--tw-bg-opacity) );
}

.btn:focus-visible {
  outline: 2px solid hsl(var(--nf) );
  outline-offset: 2px;
}

.btn-primary {
  --tw-border-opacity: 1;
  border-color: hsl(var(--p) / var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: hsl(var(--p) / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--pc) / var(--tw-text-opacity) );
}

.btn-primary:hover {
  --tw-border-opacity: 1;
  border-color: hsl(var(--pf, var(--p)) / var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: hsl(var(--pf, var(--p)) / var(--tw-bg-opacity) );
}

.btn-primary:focus-visible {
  outline: 2px solid hsl(var(--p) );
}

.btn-secondary {
  --tw-border-opacity: 1;
  border-color: hsl(var(--s) / var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: hsl(var(--s) / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--sc) / var(--tw-text-opacity) );
}

.btn-secondary:hover {
  --tw-border-opacity: 1;
  border-color: hsl(var(--sf, var(--s)) / var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: hsl(var(--sf, var(--s)) / var(--tw-bg-opacity) );
}

.btn-secondary:focus-visible {
  outline: 2px solid hsl(var(--s) );
}

.btn-outline {
  --tw-text-opacity: 1;
  color: hsl(var(--bc) / var(--tw-text-opacity) );
  background-color: rgba(0, 0, 0, 0);
  border-color: currentColor;
}

.btn-outline:hover {
  --tw-border-opacity: 1;
  border-color: hsl(var(--bc) / var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: hsl(var(--bc) / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--b1) / var(--tw-text-opacity) );
}

.btn-outline.btn-primary {
  --tw-text-opacity: 1;
  color: hsl(var(--p) / var(--tw-text-opacity) );
}

.btn-outline.btn-primary:hover {
  --tw-border-opacity: 1;
  border-color: hsl(var(--pf, var(--p)) / var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: hsl(var(--pf, var(--p)) / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--pc) / var(--tw-text-opacity) );
}

.btn-outline.btn-secondary {
  --tw-text-opacity: 1;
  color: hsl(var(--s) / var(--tw-text-opacity) );
}

.btn-outline.btn-secondary:hover {
  --tw-border-opacity: 1;
  border-color: hsl(var(--sf, var(--s)) / var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: hsl(var(--sf, var(--s)) / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--sc) / var(--tw-text-opacity) );
}

@keyframes button-pop {
  0% {
    transform: scale(var(--btn-focus-scale, .95) );
  }

  40% {
    transform: scale(1.02);
  }

  100% {
    transform: scale(1);
  }
}

.card:focus-visible {
  outline-offset: 2px;
  outline: 2px solid;
}

@keyframes checkmark {
  0% {
    background-position-y: 5px;
  }

  50% {
    background-position-y: -2px;
  }

  100% {
    background-position-y: 0;
  }
}

.divider:before, .divider:after {
  background-color: hsl(var(--bc) / var(--tw-bg-opacity) );
  --tw-bg-opacity: .1;
}

.divider:not(:empty) {
  gap: 1rem;
}

.label a:hover {
  --tw-text-opacity: 1;
  color: hsl(var(--bc) / var(--tw-text-opacity) );
}

.input-bordered {
  --tw-border-opacity: .2;
}

.input:focus {
  outline: 2px solid hsla(var(--bc) / .2);
  outline-offset: 2px;
}

.input-error {
  --tw-border-opacity: 1;
  border-color: hsl(var(--er) / var(--tw-border-opacity) );
}

.input-error:focus {
  outline: 2px solid hsl(var(--er) );
}

.link:focus {
  outline-offset: 2px;
  outline: 2px solid rgba(0, 0, 0, 0);
}

.link:focus-visible {
  outline-offset: 2px;
  outline: 2px solid;
}

@keyframes progress-loading {
  50% {
    left: 107%;
  }
}

@keyframes radiomark {
  0% {
    box-shadow: 0 0 0 12px hsl(var(--b1) ) inset, 0 0 0 12px hsl(var(--b1) ) inset;
  }

  50% {
    box-shadow: 0 0 0 3px hsl(var(--b1) ) inset, 0 0 0 3px hsl(var(--b1) ) inset;
  }

  100% {
    box-shadow: 0 0 0 4px hsl(var(--b1) ) inset, 0 0 0 4px hsl(var(--b1) ) inset;
  }
}

@keyframes rating-pop {
  0% {
    transform: translateY(-.125em);
  }

  40% {
    transform: translateY(-.125em);
  }

  100% {
    transform: translateY(0);
  }
}

.tab:hover {
  --tw-text-opacity: 1;
}

.tab.tab-active {
  border-color: hsl(var(--bc) / var(--tw-border-opacity) );
  --tw-border-opacity: 1;
  --tw-text-opacity: 1;
}

.tab:focus {
  outline-offset: 2px;
  outline: 2px solid rgba(0, 0, 0, 0);
}

.tab:focus-visible {
  outline-offset: -3px;
  outline: 2px solid;
}

.tab:focus-visible.tab-lifted {
  border-bottom-right-radius: var(--tab-radius, .5rem);
  border-bottom-left-radius: var(--tab-radius, .5rem);
}

.tab-lifted {
  border: var(--tab-border, 1px) solid transparent;
  border-width: 0 0 var(--tab-border, 1px) 0;
  border-top-left-radius: var(--tab-radius, .5rem);
  border-top-right-radius: var(--tab-radius, .5rem);
  border-bottom-color: var(--tab-border-color);
  padding-left: var(--tab-padding, 1rem);
  padding-right: var(--tab-padding, 1rem);
  padding-top: var(--tab-border, 1px);
}

.tab-lifted.tab-active {
  background-color: var(--tab-bg);
  border-width: var(--tab-border, 1px) var(--tab-border, 1px) 0 var(--tab-border, 1px);
  border-left-color: var(--tab-border-color);
  border-right-color: var(--tab-border-color);
  border-top-color: var(--tab-border-color);
  padding-left: calc(var(--tab-padding, 1rem)  - var(--tab-border, 1px) );
  padding-right: calc(var(--tab-padding, 1rem)  - var(--tab-border, 1px) );
  padding-bottom: var(--tab-border, 1px);
  padding-top: 0;
}

.tab-lifted.tab-active:before, .tab-lifted.tab-active:after {
  z-index: 1;
  content: "";
  width: var(--tab-radius, .5rem);
  height: var(--tab-radius, .5rem);
  --tab-grad: calc(68% - var(--tab-border, 1px) );
  --tab-corner-bg: radial-gradient(circle at var(--circle-pos), transparent var(--tab-grad), var(--tab-border-color) calc(var(--tab-grad)  + .3px), var(--tab-border-color) calc(var(--tab-grad)  + var(--tab-border, 1px) ), var(--tab-bg) calc(var(--tab-grad)  + var(--tab-border, 1px)  + .3px) );
  display: block;
  position: absolute;
  bottom: 0;
}

.tab-lifted.tab-active:before {
  left: calc(var(--tab-radius, .5rem) * -1);
  --circle-pos: top left;
  background-image: var(--tab-corner-bg);
}

.tab-lifted.tab-active:after {
  right: calc(var(--tab-radius, .5rem) * -1);
  --circle-pos: top right;
  background-image: var(--tab-corner-bg);
}

.tab-lifted.tab-active:first-child:before, .tab-lifted.tab-active:last-child:after, .tab-lifted.tab-active + .tab-lifted.tab-active:before {
  background: none;
}

.textarea-bordered {
  --tw-border-opacity: .2;
}

.textarea:focus {
  outline: 2px solid hsla(var(--bc) / .2);
  outline-offset: 2px;
}

.badge-sm {
  height: 1rem;
  padding-left: .438rem;
  padding-right: .438rem;
  font-size: .75rem;
  line-height: 1rem;
}

.btn-block {
  width: 100%;
}

.badge-error {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--er) / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--erc, var(--nc)) / var(--tw-text-opacity) );
  border-color: rgba(0, 0, 0, 0);
}

.invisible {
  visibility: hidden;
}

.relative {
  position: relative;
}

.m-1 {
  margin: .25rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mt-4 {
  margin-top: 1rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.ml-2 {
  margin-left: .5rem;
}

.mr-auto {
  margin-right: auto;
}

.ml-auto {
  margin-left: auto;
}

.mr-2 {
  margin-right: .5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mt-12 {
  margin-top: 3rem;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.hidden {
  display: none;
}

.h-full {
  height: 100%;
}

.h-4 {
  height: 1rem;
}

.max-h-full {
  max-height: 100%;
}

.w-4 {
  width: 1rem;
}

.grow {
  flex-grow: 1;
}

.flex-col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.overflow-auto {
  overflow: auto;
}

.whitespace-pre {
  white-space: pre;
}

.rounded-lg {
  border-radius: .5rem;
}

.bg-neutral-focus {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nf, var(--n)) / var(--tw-bg-opacity) );
}

.bg-base-300 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b3, var(--b2)) / var(--tw-bg-opacity) );
}

.stroke-current {
  stroke: currentColor;
}

.shadow-2xl {
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, .25);
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.scrollbar-xs {
  --scrollbar-track: initial;
  --scrollbar-thumb: initial;
  scrollbar-color: var(--scrollbar-thumb) var(--scrollbar-track);
  overflow: overlay;
}

.scrollbar-xs::-webkit-scrollbar-track {
  background-color: var(--scrollbar-track);
}

.scrollbar-xs::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb);
}

.scrollbar-xs {
  scrollbar-width: xs;
}

.scrollbar-xs::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

* {
  font-size: 16px !important;
}

html, body {
  min-height: 100vh;
}

body.fontLoaded {
  font-family: Roboto, sans-serif !important;
}

.tabs {
  margin-bottom: -1px;
}

.tab-lifted {
  color: hsl(var(--s) );
  border-bottom: none;
}

.tab-lifted:hover {
  color: hsl(var(--pf) );
}

.tab-active {
  color: hsl(var(--p) );
  background-color: hsl(var(--b3) ) !important;
}

.tab-active:hover {
  color: hsl(var(--p) );
}

.tab-lifted.tab-active:first-child:before, .tab-lifted.tab-active:last-child:after, .tab-lifted.tab-active + .tab-lifted.tab-active:before {
  background-image: var(--tab-corner-bg);
}

.active .card-body {
  padding-bottom: 0;
}

.card-body {
  gap: 0;
  padding: 1.5rem;
}

.card:not(.active):not(.hidden) .card-body {
  padding-top: 1rem;
}

.generator-output {
  min-width: 20rem;
  max-height: 14rem;
}

.generator-output::-webkit-scrollbar-track {
  --scrollbar-track: hsl(var(--n) );
  border-radius: 10px;
}

.generator-output::-webkit-scrollbar-thumb {
  --scrollbar-thumb: hsl(var(--a) );
  border-radius: 10px;
}

input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.spacer {
  height: 4.5rem;
}

.input-group-vertical :last-child.badge {
  border-top-left-radius: var(--rounded-btn, .5rem);
  border-top-right-radius: var(--rounded-btn, .5rem);
}

.card.active .input-group:not(:last-of-type), div.textarea {
  margin-bottom: 1rem;
}

#left-skyscraper, #right-skyscraper {
  min-width: 160px;
  max-width: 160px;
  min-height: 600px;
  max-height: 600px;
}

#top-mobile-banner, #bottom-mobile-banner {
  min-width: 300px;
  max-width: 300px;
  min-height: 50px;
  max-height: 100px;
}

@media (min-width: 768px) {
  .md\:flex {
    display: flex;
  }

  .md\:hidden {
    display: none;
  }

  .md\:flex-row {
    flex-direction: row;
  }
}

@media (min-width: 1024px) {
  .lg\:ml-8 {
    margin-left: 2rem;
  }

  .lg\:mr-8 {
    margin-right: 2rem;
  }
}

@media (min-width: 1280px) {
  .xl\:ml-12 {
    margin-left: 3rem;
  }

  .xl\:mr-12 {
    margin-right: 3rem;
  }
}

@media (min-width: 1536px) {
  .\32 xl\:ml-16 {
    margin-left: 4rem;
  }

  .\32 xl\:mr-16 {
    margin-right: 4rem;
  }
}

/*# sourceMappingURL=index.56c7060f.css.map */
