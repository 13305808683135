@tailwind base;
@tailwind components;
@tailwind utilities;


* {
  font-size: 16px !important;
}

html,
body {
  min-height: 100vh
}

body.fontLoaded {
  font-family: 'Roboto', sans-serif !important;
}

.tabs {
  margin-bottom: -1px;
}

.tab-lifted {
  border-bottom: none;
  color: hsl(var(--s));
}

.tab-lifted:hover {
  color: hsl(var(--pf))
}

.tab-active {
  background-color: hsl(var(--b3)) !important;
  color: hsl(var(--p))
}

.tab-active:hover {
  color: hsl(var(--p));
}

.tab-lifted.tab-active:first-child:before,
.tab-lifted.tab-active:last-child:after,
.tab-lifted.tab-active+.tab-lifted.tab-active:before {
  background-image: var(--tab-corner-bg);
}

.active .card-body {
  padding-bottom: 0;
}

.card-body {
  gap: 0;
  padding: 1.5rem;
}

.card:not(.active):not(.hidden) .card-body {
  padding-top: 1rem;
}

.generator-output {
  min-width: 20rem;
  max-height: 14rem;
}

.generator-output::-webkit-scrollbar-track {
  border-radius: 10px;
  --scrollbar-track: hsl(var(--n));
}

.generator-output::-webkit-scrollbar-thumb {
  border-radius: 10px;
  --scrollbar-thumb: hsl(var(--a));
}

/*
 * https://stackoverflow.com/questions/3790935/can-i-hide-the-html5-number-input-s-spin-box
 */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.spacer {
  height: 4.5rem;
}

.input-group-vertical :last-child.badge {
  border-top-left-radius: var(--rounded-btn,.5rem);
  border-top-right-radius: var(--rounded-btn,.5rem);
}

.card.active .input-group:not(:last-of-type),
div.textarea {
  margin-bottom: 1rem;
}

#left-skyscraper {
  min-width: 160px;
  max-width: 160px;
  min-height: 600px;
  max-height: 600px;
}

#right-skyscraper {
  min-width: 160px;
  max-width: 160px;
  min-height: 600px;
  max-height: 600px;
}

#top-mobile-banner {
  min-width: 300px;
  max-width: 300px;
  min-height: 50px;
  max-height: 100px;
}

#bottom-mobile-banner {
  min-width: 300px;
  max-width: 300px;
  min-height: 50px;
  max-height: 100px;
}
